/* global
 Sentry
 */

/* eslint no-unused-vars: 0 */

// #############################################################################
// SENTRY

const dsn = document.body.dataset.sentryDsn;

if (dsn) {
  Sentry.init({
    dsn: dsn,
    environment: document.body.dataset.sentryEnv,
    release: document.body.dataset.version,
  });
}

// #############################################################################
// AJAX SETUP

function csrfSafeMethod (method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

$.ajaxSetup({
  cache: false,
  beforeSend: function ($xhr, $settings) {
    if (!csrfSafeMethod($settings.type) && !this.crossDomain) {
      $xhr.setRequestHeader(
        "X-CSRFToken", $("[name=csrfmiddlewaretoken]").val()
      );
    }
  },
  error: function (e) {
    console.log("ERROR:", e, e.status, e.statusText);
  },
});

// #############################################################################
//  HELPERS

const $x = {};

$x.ajax = {
  $defaults: {
    dataType: "text",
    data: {},
    responseType: undefined,
    beforeSend: ($xhr) => {
    },
    error: ($xhr) => {
      console.log("ERROR:", $xhr, $xhr.status, $xhr.statusText);
    },
    success: ($data, $xhr) => {
    },
  },

  _getData ($settings) {
    if ($settings.data instanceof FormData) {
      return $settings.data;
    }

    const $data = new FormData();

    for (const key in $settings.data) {
      $data.append(key, $settings.data[key]);
    }

    return $data;
  },

  fetch (method, url, $settings) {
    const _this = this;
    const $xhr = new XMLHttpRequest();

    $settings = { ..._this.$defaults, ...$settings };

    $xhr.open(method, url);
    $settings.beforeSend($xhr);

    $xhr.onreadystatechange = () => {
      if ($xhr.readyState === XMLHttpRequest.DONE) {
        const status = $xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully

          if ($settings.success) {
            if ($settings.dataType === "json") {
              $settings.success(JSON.parse($xhr.responseText), $xhr);
            } else {
              $settings.success($xhr.responseText, $xhr);
            }
          }
        } else {
          $settings.error($xhr);
        }
      }
    };

    // The header X-Requested-With allows server side frameworks, such as Django,
    // to identify Ajax requests. It's optional, but can be useful.
    $xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    if ($settings.responseType) {
      $xhr.responseType = $settings.responseType;
    }

    $xhr.send(_this._getData($settings));

    return $xhr;
  },

  get (url, $settings = {}) {
    const _this = this;

    _this.fetch("GET", url, $settings);
  },

  post (url, $settings = {}) {
    const _this = this;

    _this.fetch("POST", url, $settings);
  },
};

/*
  delegateEvent ist keyfeature von jquery ursprünglich damit man zb events
  auf nachträglich geladenen html elementen funktionieren.. zb setze ich
  eventlistener auf inputfeld A das erst später dynamisch erstellt wird und damit
  der listener dann auch noch funktioniert nimmt man diesen code.. wurde
  einfach auf vanillajs übersetzt damit es ohne JQUERY zukünftig auch funktioniert
*/
$x.delegateEvent = {
  on ($element, events, selector, handler) {
    $element.addEventListener(events, function (e) {
      const $elements = document.querySelectorAll(selector);

      $elements.forEach(function ($element) {
        if ($element.contains(e.target)) {
          return handler.apply($element, [e]);
        }
      });
    }, true);
  },
};

/*
  html code in irgendeinem element ersetzen.. jquery function
  auf vanillaJS übersetzt - gilt für alle html/inserAFter funktionien hier unten..
 */
$x.html = function ($element, html) {
  const $oldElement = $element;
  const $newElement = $oldElement.cloneNode(false);

  $newElement.innerHTML = html;
  $oldElement.parentNode.replaceChild($newElement, $oldElement);

  return $newElement;
};

$x.insertAfter = function ($newElement, $element) {
  $element.parentNode.insertBefore($newElement, $element.nextSibling);
};

$x.remove = function ($elements) {
  $elements.forEach(function ($element) {
    $element.parentNode.removeChild($element);
  });
};

$x.replaceHtml = function ($data, $parent = document) {
  /**
   * replaceHtml() updates multiple HTML content from ajax response.
   *
   * Args:
   *  $data (obj): JSON object
   *
   * Example:
   *  $data = {
   *    "updates": [
   *      {
   *        "wrapper": "#id_to_insert_content",
   *        "content": "<p>Updated content</p>",
   *      },
   *    ],
   *  }
   *
   **/

  if ($data.updates) {
    $data.updates.forEach(function ($item) {
      const $elements = document.querySelectorAll($item.wrapper);

      $elements.forEach(function ($element) {
        $x.html($element, $item.content);
      });
    });
  }
};

/**
 * Modal:
 *
 * $x.modal.open(url, {
 *  beforeModalOpen: function ($modal, $data) {
 *    // $modal = jQuery object from the <div data-modal>
 *    // $data = JSON response
 *  },
 *  onModalOpen: function ($modal, $data) {
 *    // $modal = jQuery object from the <div data-modal>
 *    // $data = JSON response
 *  },
 *  onModalClose: function ($modal) {
 *    // $modal = jQuery object from the <div data-modal>
 *  },
 * });
 *
 **/

$x.modal = {
  $defaults: {
    beforeModalOpen: ($modal, $data) => {
      if ($data.submit === "error") {
        if ($data.toaster) {
          $("body").toaster("updateToaster", $data.toaster);
        }
      }
    },
    onModalOpen: ($modal, $data) => {
    },
    onModalClose: ($modal) => {
    },
  },

  open (url, $settings = {}) {
    const _this = this;
    const $modalWrapper = $("#modal_wrapper");

    // WORKAROUND: backdrop not removed when modal open other modal!
    $x.remove(document.querySelectorAll(".modal-backdrop"));

    $settings = { ..._this.$defaults, ...$settings };

    $x.ajax.get(url, {
      success: function ($data) {
        $modalWrapper.html($data);

        const $modal = $("[data-modal]", $modalWrapper).modal();

        $settings.beforeModalOpen($modal, $data);

        $modal.on("shown.bs.modal", () => {
          // Magic 🦄: Needed for popups in modal (e. g. CKEditor5 Link URL)
          $(document).off("focusin.modal");
          $settings.onModalOpen($modal, $data);
        });

        $modal.on("hidden.bs.modal", () => {
          $settings.onModalClose($modal);
          $modalWrapper.empty();
        });
      },
    });
  },
};

/**
 * $x.formatFileSize() outputs human readable file size.
 *
 * Args:
 *  bytes (int): Bytes
 *  decimal_point (int): Decimal point
 *
 * Returns:
 *  str: A human readable string with unit.
 *
 **/

$x.formatFileSize = function (bytes, decimal_point) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1000;
  const dm = decimal_point || 2;
  const sizes = ["Bytes", "kb", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * $x.escapeText() escape HTML tags from string.
 *
 **/

$x.escapeText = function (text) {
  const tags_to_replace = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
  };

  return text.replace(/[&<>]/g, function (tag) {
    return tags_to_replace[tag] || tag;
  });
};

/**
 * $x.removeHtml() remove HTML tags from string.
 *
 **/

$x.removeText = function (text) {
  return text.replace(/<\/?[^>]+(>|$)/g, "").trim();
};

// #############################################################################
// JQUERY PLUGIN HELPER
// Alle jQueryPlugins verwenden diese initPlugin Funktion
//

function initPlugin (Plugin, plugin_name) {
  return function ($options) {
    const $args = Array.prototype.slice.call(arguments, 1);

    if ($options === undefined || typeof $options === "object") {
      return this.each(function () {
        if (!$.data(this, "plugin_" + plugin_name)) {
          $.data(this, "plugin_" + plugin_name, new Plugin(this, $options));
        }
      });
    } else if (typeof $options === "string") {
      this.each(function () {
        const $instance = $.data(this, "plugin_" + plugin_name);

        if ($instance && typeof $instance[$options] === "function") {
          $instance[$options].apply($instance, $args);
        } else {
          throw new Error("Method " + $options + " does not exist on jQuery." + plugin_name);
        }
      });
    }
  };
}

// #############################################################################
// REDIRECT

function checkRedirect ($data) {
  if ($data.toaster) {
    $("body").toaster("saveToaster", $data.toaster);
  }

  window.location.href = $data.redirect;
}

// #############################################################################
// BETTER FOCUS
// Funktion ursprünglich von APPLE 😍😍😍😍😍😍😍😍😍😍
// wenn man im browser mit tab durch webseite navigiert dann macht er bei
// bei links eine umrandung damit man link sieht. default ist diese umrandung auch
// bei klick kurz angezeigt.. das sieht halt ziemlich mies aus und braucht man nicht
// BetterFocus garantiert, dass diese umrandung nur bei keyboard tab event
// ungezeigt wird und nicht bei klick. vorteil man kann diese markierungen
// markanter machen und da macht es sinn bei keyboard aber bei klick soll es
// dann niemals sichtbar sein

$x.BetterFocus = class {
  constructor ($options) {
    const $defaults = {
      selector: "a, [tabindex]",
    };

    this._$settings = { ...$defaults, ...$options };

    this._focusMethod = false;
    this._lastFocusMethod = false;
  }

  init () {
    const _this = this;

    $x.delegateEvent.on(document, "focus", "a, [tabindex]", function (e) {
      if (!_this._focusMethod) {
        _this._focusMethod = _this._lastFocusMethod;
      }

      const $elements = document.querySelectorAll(_this._$settings.selector);

      $elements.forEach(function ($element) {
        $element.dataset.focusMethod = _this._focusMethod;
      });

      _this._lastFocusMethod = _this._focusMethod;
      _this._focusMethod = false;
    });

    $x.delegateEvent.on(document, "blur", "a, [tabindex]", function () {
      const $elements = document.querySelectorAll(_this._$settings.selector);

      $elements.forEach(function ($element) {
        $element.removeAttribute("data-focus-method");
      });
    });

    $x.delegateEvent.on(document, "keydown", "a, [tabindex]", function () {
      _this._focusMethod = "key";
    });

    $x.delegateEvent.on(document, "mousedown", "a, [tabindex]", function () {
      if (_this._focusMethod === "touch") {
        return;
      }

      _this._focusMethod = "mouse";
    });

    $x.delegateEvent.on(document, "touchstart", "a, [tabindex]", function () {
      _this._focusMethod = "touch";
    });

    window.addEventListener("blur", function () {
      _this._focusMethod = false;
    });
  }
};

// #############################################################################
// DOWNLOAD BLOB

/**
 * Initial:
 *
 * const $downloadBlob = new $x.DownloadBlob({
 *   onDownloadStarted: function ($data) {
 *     // $data = JSON response
 *   },
 * });
 *
 * $downloadBlob.download(href);
 *
 **/

$x.DownloadBlob = class {
  constructor ($options) {
    const $defaults = {
      onDownloadStarted: function ($data) {
      },
    };

    this.$settings = { ...$defaults, ...$options };
  }

  download (href) {
    const _this = this;

    $.ajax({
      dataType: "json",
      type: "GET",
      url: href,
      success: function ($data) {
        _this.$settings.onDownloadStarted($data);

        if ($data.base64) {
          const $blob = _this._base64toBlob($data.base64, $data.content_type);
          _this._downloadBlob($blob, $data.file_name);
        }
      },
    });
  }

  _downloadBlob ($data, file_name) {
    const url = window.URL || window.webkitURL;
    const $a = $("<a>");

    $("body").append($a);

    $a[0].href = url.createObjectURL($data);
    $a[0].download = file_name;
    $a[0].click();

    window.URL.revokeObjectURL(url);
    $a.remove();
  }

  _base64toBlob (data, content_type, slice_size = 512) {
    const $byte_characters = atob(data);
    const $byte = [];

    for (let offset = 0; offset < $byte_characters.length; offset += slice_size) {
      const slice = $byte_characters.slice(offset, offset + slice_size);

      const $byte_numbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        $byte_numbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array($byte_numbers);

      $byte.push(byteArray);
    }

    return new Blob($byte, {
      type: content_type,
    });
  }
};

// #############################################################################
// CLIPBOARD
// klick auf element kopiert zb inhalt in die zwischenablage

/**
 * Initial:
 *
 * <input id="target" value="Copy text">
 * <a data-clipboard="copy" data-clipboard-target="#target">Copy</a>
 *
 * or
 *
 * <a data-clipboard data-clipboard-text="Copy text">Copy</a>
 *
 * $("body").clipBoard({
 *  selector: "[data-clipboard]",
 *  beforeCopyText: function ($target, text) {
 *    // $target = jQuery object from [data-clipboard-target]
 *    // text = Text to be copied
 *    return text;
 *  },
 * });
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "clipBoard";

  const $defaults = {
    selector: "[data-clipboard]",
    beforeCopyText: function ($target, text) {
      return text;
    },
  };

  class clipBoardPlugin {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.el = $element;

      this.$el = $($element);
      this.$dummy = undefined;

      this.init();
    }

    init () {
      const _this = this;

      _this.$el.on("click", _this.$settings.selector, function () {
        const $this = $(this);

        const text = $this.data("clipboard-text");
        const target = $this.data("clipboard-target");

        _this.$target = $(target);

        _this.action = $this.data("clipboard");

        if (text) {
          _this.action = "copy";

          _this._fakeSelectText(text);
        } else {
          _this._selectText();
        }

        _this._copyText();
        _this._clearSelection();

        return false;
      });
    }

    _fakeSelectText (select_text) {
      const _this = this;

      select_text = _this.$settings.beforeCopyText(
        _this.$target, select_text
      );

      _this.$dummy = $("<textarea>");
      _this.$dummy.val(select_text);

      _this.$el.append(_this.$dummy);

      _this.$dummy.get(0).select();
      _this.$dummy.get(0).setSelectionRange(0, select_text.length);

      return select_text;
    }

    _selectText () {
      const _this = this;
      let select_text;

      if (_this.$target.is("select")) {
        select_text = $("option:selected", _this.$target).text().trim();
        _this._fakeSelectText(select_text);
      } else if (_this.$target.is("input") || _this.$target.is("textarea")) {
        const is_read_only = _this.$target.attr("readonly");
        const select_text = _this.$target.val().trim();

        if (!is_read_only) {
          _this.$target.prop("readonly", true);
        }

        if (_this.action === "copy") {
          _this._fakeSelectText(select_text);
        } else {
          _this.$target.get(0).select();
          _this.$target.get(0).setSelectionRange(0, select_text.length);
        }

        if (!is_read_only) {
          _this.$target.removeAttr("readonly");
        }
      }
    }

    _clearSelection () {
      const _this = this;

      document.activeElement.blur();
      window.getSelection().removeAllRanges();

      _this.$el.focus();

      if (_this.$dummy) {
        _this.$dummy.remove();
      }
    }

    _copyText () {
      const _this = this;

      document.execCommand(_this.action);
    }
  }

  $.fn[plugin_name] = initPlugin(clipBoardPlugin, plugin_name);
})(jQuery);

// #############################################################################
// TOASTER

/**
 * Initial:
 *
 * <a data-toaster="{% url "toaster" %}" data-toaster-text="Toaster text">Link</a>
 *
 * $("body").toaster({
 *  selector: "[data-toaster]",
 * });
 *
 * Update toaster:
 *
 * $("body").toaster("updateToaster", toaster_html);
 *
 * Save toaster:
 *
 * $("body").toaster("saveToaster", toaster_html);
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "toaster";

  const $defaults = {
    selector: "[data-toaster]",
  };

  class toasterPlugin {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.el = $element;

      this.$el = $($element);
      this.$wrapper = $("#toaster_wrapper");

      this.init();
    }

    init () {
      const _this = this;

      _this.$el.on("click", _this.$settings.selector, function () {
        const $this = $(this);
        const toaster = $this.data("toaster");

        $.ajax({
          data: {
            success: true,
            text: $this.data("toaster-text"),
          },
          type: "POST",
          url: toaster,
          success: function ($data) {
            _this.updateToaster($data.toaster);
          },
        });
      });

      _this._restoreToaster();
    }

    _restoreToaster () {
      const _this = this;
      const toaster = sessionStorage.getItem("toaster");

      if (toaster) {
        _this.updateToaster(toaster);
        sessionStorage.removeItem("toaster");
      }
    }

    saveToaster (toaster_html) {
      sessionStorage.setItem("toaster", toaster_html);
    }

    updateToaster (toaster_html) {
      const _this = this;
      _this.$wrapper.prepend(toaster_html);

      const $toast = $(".toast:not(.fade)", _this.$wrapper);
      $toast.toast("show");

      $toast.on("show.bs.toast", function () {
        $(this).toast("dispose");
      });
    }
  }

  $.fn[plugin_name] = initPlugin(toasterPlugin, plugin_name);
})(jQuery);

// #############################################################################
// AUTO UPDATE HTML CONTENT
// content view/html gerenderte seite lädt sich selber immer wieder neu
// zb habe ich eine html content in einem block geladen und ich geb einfach
// eine URL mit und die wird immer wieder aufgerufen und html content wird
// direkt wieder ersetzt..
// intervall wie oft das passieren soll kann mitgeben werden.. default alle 10 sekunden

/**
 * Initial:
 *
 * <div data-update-html-content="/UPDATE_URL/">
 *   Auto update content inside this div.
 * </div>
 *
 * UPDATE_URL is a JSON file like in replaceHtml()
 *
 * $("body").autoUpdateHtmlContent({
 *  selector: "[data-update-html-content]",
 * });
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "autoUpdateHtmlContent";

  const $defaults = {
    selector: "[data-update-html-content]",
    update_interval: 10000,
  };

  class autoUpdateHtmlContent {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.$el = $($element);
      this.$wrappers = $("[data-update-html-content]", $element);

      this.init();
    }

    init () {
      const _this = this;

      _this.$wrappers.each(function () {
        const url = this.getAttribute("data-update-html-content");

        if (url) {
          const $element = this;
          const interval_time = this.getAttribute("data-update-interval") || _this.$settings.update_interval;

          _this._update($element, url);

          _this.interval = setInterval(function () {
            _this._update($element, url);
          }, interval_time);
        }
      });
    }

    _update ($wrapper, url) {
      $.ajax({
        dataType: "json",
        url: url,
        success: function ($data) {
          $x.replaceHtml($data, $wrapper);
        },
      });
    }
  }

  $.fn[plugin_name] = initPlugin(autoUpdateHtmlContent, plugin_name);
})(jQuery);

// #############################################################################
// COLLAPSE
// Verwendet Bootstrap funktionalität - zb bei datatable filter ein und ausklappen
// von content mittels button

(function initCollapse () {
  const $collapse = $(".collapse");

  function getButton (id) {
    return document.querySelector("[data-target=\"#" + id + "\"]");
  }

  function setState ($button, id, state) {
    localStorage.setItem("collapseState_" + id, state);
    $button.setAttribute("aria-pressed", state === "show" ? "true" : "false");
  }

  $collapse.each(function () {
    const $button = getButton(this.id);

    if ($button) {
      const hasAriaPressed = $button.getAttribute("aria-pressed");

      if (hasAriaPressed) {
        const collapseState = localStorage.getItem("collapseState_" + this.id);

        if (collapseState) {
          $(this).collapse(collapseState);
          setState($button, this.id, collapseState);
        } else {
          if (hasAriaPressed === "true") {
            localStorage.setItem("collapseState_" + this.id, "show");
          } else if (hasAriaPressed === "false") {
            localStorage.setItem("collapseState_" + this.id, "hide");
          }
        }
      }
    }
  });

  $collapse.on("hide.bs.collapse", function () {
    const $button = getButton(this.id);
    setState($button, this.id, "hide");
  });

  $collapse.on("show.bs.collapse", function () {
    const $button = getButton(this.id);
    setState($button, this.id, "show");
  });
})();

// #############################################################################
// FANCY BOX
// Bildergalerie die mit Tastatur durchschalten erlaubt
//

(function initFancyBox () {
  const $fancybox = $("[data-fancybox]");

  if ($fancybox.length === 0) {
    return;
  }

  $fancybox.fancybox({
    buttons: [
      "download",
      "fullScreen",
      "close",
    ],
    protect: true,
    afterLoad: function (instance, current) {
      const pixel_ratio = window.devicePixelRatio || 1;

      if (pixel_ratio > 1.5) {
        current.width = current.width / 2;
        current.height = current.height / 2;
      }
    },
  });
})();

(function initFancyBoxPDF () {
  const $fancybox = $("[data-fancybox-pdf]");

  if ($fancybox.length === 0) {
    return;
  }

  $fancybox.fancybox({
    toolbar: false,
    smallBtn: true,
    iframe: {
      preload: true,
    },
  });
})();


// #############################################################################
//  EXPAND TEXT
// Lange texte kurz + Mehranzeigen feature..
// innerhalb von spalten von datatable praktisch

class ExpandText {
  constructor ($options) {
    const $defaults = {
      showLongTextSelector: "[data-show-long-text]",
      showShortTextSelector: "[data-show-short-text]",
    };

    this._$settings = { ...$defaults, ...$options };
  }

  init () {
    const _this = this;

    $x.delegateEvent.on(document, "click", _this._$settings.showLongTextSelector, function (e) {
      e.preventDefault();

      const [$shortText, $longText] = _this._getElements(this);

      $shortText.hidden = true;
      $longText.hidden = false;
    });

    $x.delegateEvent.on(document, "click", _this._$settings.showShortTextSelector, function (e) {
      e.preventDefault();

      const [$shortText, $longText] = _this._getElements(this);

      $shortText.hidden = false;
      $longText.hidden = true;
    });
  }

  _getElements ($element) {
    const $expandText = $element.closest("[data-expand-text]");
    const $shortText = $expandText.querySelector("[data-short-text]");
    const $longText = $expandText.querySelector("[data-long-text]");

    return [$shortText, $longText];
  }
}

const $expandText = new ExpandText();
$expandText.init();
